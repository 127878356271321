<template>
  <div class="record-contaner">
    <el-row class="device-list">
      <el-row type="flex" align="middle" justify="space-between">
        <p class="title">意向记录</p>
      </el-row>
    </el-row>
    <div class="abox">
      <el-row
        class="good-detail"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <p>商品信息</p>
        <p>查看</p>
      </el-row>

      <!-- 案例 -->
      <el-row class="case">
        <div v-if="intentionList.length">
          <el-row
            class="item"
            type="flex"
            align="middle"
            v-for="(item, index) in intentionList"
            :key="index"
          >
            <div class="msg-box">
              <img :src="item.mainImg" alt="" />
              <el-row class="contant-box">
                <el-row class="box">
                  <p class="contant">
                    {{ item.briefTitle ? item.briefTitle : "暂无描述" }}
                  </p>
                  <p class="date">
                    {{
                      item.isNegotiable
                        ? "面议"
                        : "¥" + Number(item.showPrice).toFixed(2)
                    }}
                  </p>
                  <!-- item.isNegotiable ? "面议" : item.showPrice.toFixed(2) -->
                </el-row>
              </el-row>
            </div>
            <div class="handle">
              <span
                ><router-link
                  :to="{ name: 'detail', query: { id: item.goodsId } }"
                  >商品详情
                </router-link></span
              >
              <span class="intention"
                ><el-button type="text" @click="toRecord(item)"
                  >意向记录</el-button
                ></span
              >
            </div>
          </el-row>
        </div>
        <el-empty style="width: 100%" v-else description="暂无数据"></el-empty>
      </el-row>
      <!-- 分页开始 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
      <!-- 分页结束 -->
    </div>

    <!-- 对话框 -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">意向详情</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <span class="label c3 f14">姓名:</span>
          <span>{{ intention.fullName }}</span>
        </p>
        <p>
          <span class="label c3 f14">联系电话：</span>
          <span>{{ intention.mobile }}</span>
        </p>
        <p>
          <span class="label c3 f14">公司名称：</span>
          <span>{{ intention.companyName }}</span>
        </p>
        <p>
          <span class="label c3 f14">地区：</span>
          <span>{{ pos }}</span>
        </p>
        <p>
          <span class="label c3 f14">备注：</span>
          <span class="remarks">{{ intention.remarks }}</span>
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangRecord",

  data() {
    return {
      assderVisible: false, //客服弹窗

      intentionList: [], //所有记录

      // 分页相关数据
      page: 1,
      pageSize: 3,
      listTotal: 0,

      intention: {}, //单个记录详情
    };
  },

  computed: {
    pos() {
      let provinceName = this.intention.provinceName
        ? this.intention.provinceName
        : "";
      let cityName = this.intention.cityName ? this.intention.cityName : "";
      let areaName = this.intention.areaName ? this.intention.areaName : "";
      let address = this.intention.address ? this.intention.address : "";
      let pos = provinceName + " " + cityName + " " + areaName + " " + address;
      return pos;
      // if (pos == "undefinde") {
      //   console.log(typeof pos);
      //   return pos;
      // } else {
      //   return "暂无地址信息";
      // }
    },
  },

  mounted() {},

  created() {
    // 维修意向-分页列表查询
    this.buyIntentionList();
  },

  methods: {
    // 意向记录
    toRecord(item) {
      this.assderVisible = true;
      let paramData = {
        id: item.id,
      };
      this.$API
        .byIntentionById(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.intention = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 维修意向-分页列表查询
    buyIntentionList() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      this.$API
        .buyIntentionList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.intentionList = res.data.records;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.buyIntentionList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 商品详情
    goodDatile() {},
    // 意向记录
    intentionRecord() {},
  },
};
</script>

<style lang="scss">
.record-contaner {
  .remarks {
    width: 344px;
    // height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  width: 100%;
  height: 100%;
  .abox {
    border: 1px solid #eeeeee;
  }
  box-sizing: border-box;
  padding: 32px;
  .device-list {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 32px;
    margin-top: 0px !important;
    .handle {
      height: 55px;
      cursor: pointer;
      .edit {
        font-size: 14px;
        color: #007bd9;
        margin-right: 32px;
      }
      .clear {
        font-size: 14px;
        color: #ff0015;
      }
    }
    .title {
      padding-bottom: 16px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      border: none;
    }
  }
  .good-detail {
    width: 909px;
    height: 54px;
    background: #f8f9fb;
    // margin-top: 24px;
    box-sizing: border-box;
    padding: 0 38px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 24px;
  }
  .case {
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    .msg-box {
      float: left;
    }
    .handle {
      height: 102px;
      line-height: 102px;
      float: right;
      font-size: 14px;
      color: #333333;
      .intention {
        margin-left: 16px;
        color: #333333 !important;
        .el-button--text {
          color: #333333 !important;
        }
      }
    }
    .item {
      width: 100%;
      display: inline-block;
      padding-bottom: 24px;
      margin-bottom: 24px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;
      transition: box-shadow 0.03s;
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }

      &:nth-child(2n) {
        margin-right: 40px;
      }
      img {
        width: 94px;
        height: 94px;
        margin-right: 20px;
        vertical-align: top;
      }
      .contant-box {
        display: inline-block;
      }
      .box {
        width: 400px;
        height: 94px;
        .contant {
          font-size: 14px;
          color: #333;
          @include dot(2);
        }
        .date {
          margin-top: 48px;
          font-size: 14px;
          color: #ff0015;
          position: relative;
          top: -5px;
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
  .dialog-box {
    //对话框垂直居中
    top: 50% !important;
    transform: translateY(-50%);
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
  }
  // 自定义弹窗样式
  .assderTc {
    display: flex;
    align-items: center;
    .title {
      padding-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 32px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      .label {
        width: 70px;
        text-align: left;
      }
      height: 311px;
      box-sizing: border-box;
      padding-left: 140px;
      padding-top: 20px;
      p {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 14px;
          color: #333333;
          margin-left: 16px;
        }
      }
    }

    .btnad {
      width: 112px;
      height: 40px;
      background: rgba(246, 247, 249, 1);
      border: 1px solid #ecedef;
      opacity: 1;
      border-radius: 20px;
    }
    .btnsad {
      color: #333;
      width: 112px;
      height: 40px;
      background: rgba(255, 195, 44, 1);
      opacity: 1;
      border-radius: 20px;
    }
  }
}
</style>
